$(function() {
  $('#swipeable').swipe({
    swipe(event, direction, distance, duration, fingerCount, fingerData) {
      if (direction === 'left') {
        $('.next')[0].click(function() {});
      }
      if (direction === 'right') {
        return $('.previous')[0].click(function() {});
      }
    },
    threshold: 0,
    fingers: 'all'
  });
  return $(document).keydown(function(e) {
    if (e.keyCode === 37) {
      $('.previous')[0].click(function() {});
      return false;
    }
    if (e.keyCode === 39) {
      $('.next')[0].click(function() {});
      return false;
    }
  });
});
