// class InfiniteScroll {
//   constructor(options) {
//     this.options = options;;
//     this.$container = $(this.options.container);
//     this.nextHref = $(this.options.nextSelector).attr('href');
//
//     callback = () => {
//       return this._loadNext(() => {
//         return setTimeout(callback, 125);
//       });
//     };
//     setTimeout(callback, 125);
//   }
//
//   _pageHeight() {
//     return document.documentElement.clientHeight;
//   }
//   _contentHeight() {
//     return this.$container.height();
//   }
//   _scrollPosition() {
//     return $(this.options.scrollSelector).scrollTop();
//   }
//
//   _loadNext(callback) {
//     if (((this._contentHeight() - this._pageHeight() - this._scrollPosition() ) < 150) && this.nextHref) {
//       $(this.options.loadingSelector).addClass('loading');
//       return $.get(this.nextHref, data => {
//         $data = $(data);
//         $container = $data.find(this.options.container);
//
//         content = $container.find(this.options.contentSelector);
//         this.$container.find(this.options.prependSelector).before(content);
//
//         this.nextHref = $data.find(this.options.nextSelector).attr('href');
//
//         $(this.options.loadingSelector).removeClass('loading');
//         return callback();
//       });
//     } else {
//       return callback();
//     }
//   }
// }
//
// $(function() {
//   if ($('.photography_list').length > 0) {
//     new InfiniteScroll({
//       loadingSelector: '.infinite_scroll_loader',
//       scrollSelector: '.photography_index',
//       nextSelector: 'nav.pagination a[rel=next]',
//       container: '.photography_list',
//       // These are all namespaced to container
//       contentSelector: '.foto',
//       prependSelector: '.clear'
//     });
//   }
//   if ($('.instagram_list').length > 0) {
//     new InfiniteScroll({
//       loadingSelector: '.infinite_scroll_loader',
//       scrollSelector: '.instagram_index',
//       nextSelector: 'nav.pagination a[rel=next]',
//       container: '.instagram_list',
//       // These are all namespaced to container
//       contentSelector: '.instagram_picture',
//       prependSelector: '.clear'
//     });
//   }
//   if ($('.categorie_list').length > 0) {
//     return new InfiniteScroll({
//       loadingSelector: '.infinite_scroll_loader',
//       scrollSelector: '.categorie_index',
//       nextSelector: 'nav.pagination a[rel=next]',
//       container: '.categorie_list',
//       // These are all namespaced to container
//       contentSelector: '.foto',
//       prependSelector: '.clear'
//     });
//   }
// });
