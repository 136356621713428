function fadeOutVimeo() {
  return $('#fade_out_vimeo').fadeOut(1000, function() {
    $('.vimeo-wrapper').css({'display': 'none'});
  });
}

$(function() {
  if ($('.photography_index').length > 0) {
    if ($('#fade_out_photo').length > 0) {
      setTimeout((function() {
        return $('#fade_out_photo').fadeOut(2000);
      }), 3000);
    }

    if ($('#fade_out_vimeo').length > 0) {
      setTimeout((function() {
        fadeOutVimeo();
      }), 10200);
    }

    $photos = $('section.photography_index');
    setInterval(function() {
      scroll = $photos.scrollTop();
      return window.history.replaceState(
        {scroll: scroll},
        "");
    }
    , 500);

    return window.onpopstate = function(event) {
      scroll = event.state.scroll;
      if (scroll) { $('ul.photography_list').height(scroll + $('ul.photography_list').height() + $('ul.photography_list li').height()); }
      if (scroll) { return $photos.animate({scrollTop: scroll}); }
    };
  }
});

$(document).ready(function() {
  $('#skip-vimeo').click(function() {
    fadeOutVimeo();
  });
})
